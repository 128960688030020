let services = [
  {
    name: "Professionell coaching",
    color: "#be3b3b",
    sn: "professionell-coaching",
    desc:
      "Spännande information om professionell coaching som tjänst kommer snart.",
  },
  {
    name: "TV på webben",
    color: "#be3b3b",
    sn: "livestreaming",
    desc:
      "Vi ger professionella lösningar för live-streaming och on-Demand-services och levererar mer än bara TV på webben. Vi sänder TV direkt till era anställda på olika sätt, exempelvis från operationssalen eller från ert digitala företagsmöte på ett engagerande och inspirerande sätt. Genom att ta fram olika format som syftar till engagemang och dialog kan vi med stolthet säga att vi tagit internkommunikation till en ny nivå.",
  },
  {
    name: "Animation",
    website: "https://zel.network/project/zelcore",
    color: "#be3b3b",
    type: "gif",
    sn: "animation",
    desc:
      "Animation är ett sätt som kan förtydliga, informera eller förklara komplexa budskap på kort tid och på ett enkelt sätt. Vi producerar korta och förklarande filmer för att hjälpa er att sälja, inspirera, förklara eller beröra er målgrupp. Animation kan användas i både intern- och externkommunikation.",
  },
  {
    name: "Event marketing",
    color: "#d193b5",
    sn: "eventmarketing",
    desc:
      "Event marketing är för er som vill nå ut genom en happening som aktivt visar upp er produkt eller tjänst i det verkliga livet. Detta kanske genom ett stort event, en mindre uppslutning med ett fåtal inbjudna eller varför inte satsa på att bjuda in ett härligt gäng influencers för en snabb räckvidd? Vilka gäster ni än väljer att bjuda in, så ser vi till att ni får ett event värt att minnas!",
  },
  {
    name: "Sociala medier & content",
    color: "#4e2c6d",
    sn: "socialamedier-content",
    desc:
      "83% av den svenska befolkningen använder sig idag av sociala medier vilket gör dessa plattformar till en utmärkt plats att marknadsföra sig på. Vi hjälper er att skapa en stark social närvaro i era sociala kanaler genom att skräddarsy ett paket som fungerar för er. Vi lägger en långsiktig strategi, skapar content, mäter resultaten och håller era budskap levande så att ni kan fokusera på annat.",
  },
  {
    name: "Drönarfilmer",
    color: "#be3b3b",
    type: "video",
    sn: "dronarfilmer",
    desc:
      "Vi har en stor erfarenhet av att hantera drönare som fångar häpnadsväckande vyer från ovan. Inga fler fasta riggar och stativ, vi fångar fågelperspektivet med enkelhet. Denna filmform går att kombinera med vanlig filmproduktion och vi hjälper er genom hela processen, från idéstadie, manusskapande och dramaturgi till filmning, editering och publicering.",
  },
  {
    name: "Videoproduktion",
    color: "#2b7c6f",
    sn: "videoproduktion",
    desc:
      "Om en bild säger mer än 1000 ord, vad säger då 25 bilder i sekunden? Vi producerar företagsfilmer, intervjuer, reklamfilmer, jingels och rörlig bild för sociala medier till er. Även här hjälper vi er genom hela processen, från idéstadie, manusskapande och dramaturgi till filmning, editering och publicering.",
  },
  {
    name: "Event",
    color: "#d193b5",
    sn: "event",
    desc:
      "Vi skapar underhållning i olika former för alla typer av event, både för företag och privatpersoner. Det kan vara personal- eller kundeventet, bröllopet, julfesten eller konferensen. Vi skapar ett skräddarsytt paket för er och era önskemål. Vi erbjuder allt ifrån planering & koordinering, mat & dryck - serverad av rutinerad personal, fotografer & videografer, artister, coverband och dj:s, till föreläsare, konferenciärer, clowner och ståuppare.",
  },
  {
    name: "Fotografi",
    img:
      "https://images.pexels.com/photos/3569263/pexels-photo-3569263.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    color: "#2b7c6f",
    type: "video",
    sn: "fotografi",
    desc:
      "Vi fångar det mesta på bild! Studion vi använder oss av gör det möjligt för produktfotografering, porträtt i hög kvalitet, gruppbilder och greenscreen-arrangemang. Vi fångar också magiska ögonblick genom bilder på event, fester och sportarrangemang.",
  },
  {
    name: "Strategisk kommunikation",
    img:
      "https://images.pexels.com/photos/3569263/pexels-photo-3569263.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    color: "#2b7c6f",
    sn: "strategisk-kommunikation",
    desc:
      "Vi hjälper er med strategisk internkommunikation inom ert företag eller i er organisation. Vi genomför en nulägesanalys & målgruppsanalys och skapar en kommunikationsplan utifrån era behov. Därefter ger vi rådgivning, expertis och utför era kommunikationsaktiviteter för att nå ert organisationsmål, hela vägen från idé till en implementerad lösning.",
  },
  {
    name: "Grafisk design, logo & hemsidor",
    img:
      "https://images.pexels.com/photos/3569263/pexels-photo-3569263.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    color: "#2b7c6f",
    sn: "grafiskdesign-logotyper",
    desc:
      "En bra design lyfter era budskap! Vi hjälper till att förbättra dina kunders upplevelse av ditt företag eller produkt med hjälp av grafisk design, både digitalt och i trycksaker. Vi skapar också logotyper och bygger SEO-optimerade hemsidor i alla storlekar och former som framhäver ert företags varumärke.",
  },
];

export default services;
