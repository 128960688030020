<template>
  <fragment>
    <section class="uk-section-default uk-section">
      <div class="uk-container">
        <div
          class="uk-grid-margin uk-grid"
          uk-grid
        >
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-visible@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-text-small">
                <a href="tel:+46760946292">+46 760 94 62 92</a><br>
                <a href="mailto:info@dreamdo.se">info@dreamdo.se</a>
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-margin uk-text-center">
                <div
                  class="uk-child-width-auto uk-grid-small uk-flex-center uk-grid socialMedia"
                  uk-grid
                >
                  <div class="uk-first-column">
                    <a
                      class="uk-icon"
                      href="https://www.facebook.com/Dreamdo-106342074491642"
                    ><i class="fab fa-facebook-f" /></a>
                  </div>
                  <div class="uk-first-column">
                    <a
                      class="uk-icon"
                      href="https://linkedin.com/"
                    ><i class="fab fa-linkedin-in" /></a>
                  </div>
                  <div>
                    <a
                      class="uk-icon"
                      href="https://www.instagram.com/dreamdo.se/"
                    ><i class="fab fa-instagram" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-visible@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-text-right uk-text-small">
                © 2020 Dreamdo
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    //
  },
  methods: {}
};
</script>